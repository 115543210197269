// Details inside of template/event
import React, { lazy, Suspense, useEffect } from "react"
import { dataStore, pageStore, userStore } from "state/store-zustand";

import EventBadge from 'components/shared/eventBadge'
import Vibes from 'components/elements/vibes'

// If user is logged in, show the card menu
import AuthDialog from "components/account/authDialog"
const EditDialog = React.memo(lazy(() => import("components/forms/editDialog")));
import CardMenu from "components/elements/cards/menu"


import { font_size_large } from 'styles/variables.js'

const Details = (props) => {
  const embedded = pageStore((state) => state.embedded)
  const showCardMenu = pageStore((state) => state.showCardMenu)
  const setAndShowMessage = pageStore((state) => state.setAndShowMessage)

  const eventCurrent = dataStore((state) => state.eventCurrent)
  const showEditForm = dataStore((state) => state.showEditForm)
  const setShowEditForm = dataStore((state) => state.setShowEditForm)
  const setEventCurrent = dataStore((state) => state.setEventCurrent)
  console.log('DEBUG: setShowEditForm', showEditForm);

  const handleShowEditForm = (show, id) => {
    if (id == null) {
      //setEventCurrent(null)
    }

    setShowEditForm(show);
  }

  const handleEditSubmitted = (data) => {
    console.log('handleEditSubmitted ', data)

    if (name) {
      setAndShowMessage('Event updated successfully!')
      setShowEditForm(false)

      setTimeout(() => {
        setEventsShouldRefresh(true)
      }, 2000)
      // TODO: trigger a refresh of the event cards
    }
  }



  const isLoggedIn = userStore((state) => state.isLoggedIn)
  const user = userStore((state) => state.user)
  const [contextMenu, setContextMenu] = React.useState(null)
  const handleMenuClose = React.useCallback(() => {
    setContextMenu(null);
  }, []);

  const { eventDetails } = props
  const {
    id,
    properties,
    title
  } = eventDetails || {}

  const eventCard = {
    id,
    properties,
    title,
    ...eventDetails?.properties
  }

  useEffect(() => {
    if (eventDetails && eventDetails.id && eventDetails.id !== eventCurrent?.id) {
      setEventCurrent(eventDetails)
    }
  }, [eventDetails, setEventCurrent])

  const is_approved = eventCard?.is_approved

  const {
    description,
    hotspots_place,
    location,
    name,
    price,
    start_date,
    end_date,
    vibes = [],
    vibemap_images,
    url
  } = properties || {} // Safely destructure

  const images_featured = vibemap_images.filter(image => {
    return image?.featured === true || image.tags?.includes('featured')
  })

  const image = vibemap_images && vibemap_images.length > 0
    ? vibemap_images[0]
    : hotspots_place?.properties?.vibemap_images
      ? hotspots_place?.properties?.vibemap_images[0]
      : null

  const placeVibes = hotspots_place?.properties?.vibes
    ? hotspots_place?.properties?.vibes
    : []

  const vibesCombined = vibes.concat(placeVibes)

  const refreshByDay = (date) => {
    const d = new Date(date)
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
  }

  return (
    <>

      <AuthDialog />
      <Suspense fallback={null}>
        <EditDialog
          isMobile
          isOpen={showEditForm}
          handleCloseCallback={() => handleShowEditForm(false, null)}
          handleSubmitCallback={handleEditSubmitted}
          eventCurrent={eventCurrent}
          setOpen={setShowEditForm}
          showForwardBack={false}
          />
      </Suspense>


      {isLoggedIn
        ? <CardMenu
            contextMenu={contextMenu}
            handleMenuClose={handleMenuClose}
            //handleNewData={handleNewData}
            card={eventCard}
            menuStyle={{ top: '1.2rem', right: '7rem', position: 'absolute' }}
            primaryAction={is_approved == true ? 'disapprove' : 'approve'}
            secondaryAction={is_approved == true ? 'approve' : 'disapprove'}
            primaryActionText={is_approved == true ? '🚫 Disapprove' : '✅ Approve'}
            secondaryActionText={is_approved == true ? '✅' : '🚫'}
            showButton={showCardMenu}
            showEditForm={handleShowEditForm}
            showPlaceMenu={false}
            showEventMenu={true}
            user={user}
          />
        : null
      }

      <h1>{name ? name : title}</h1>
      <section>
        <EventBadge dateTime={start_date} />
        <img
          alt={image?.alt_text}
          caption={image?.caption}
          className='eventImage'
          loading='lazy'
          src={image?.original + '?refresh=' + refreshByDay(start_date)}
          title={title}
        />
      </section>
      <Vibes vibes={vibesCombined} numVibesShown={6} />
      <div className="description" style={{ whiteSpace: 'pre-wrap', fontSize: font_size_large }}>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div >

    </>
  )
}

export default Details
